import { graphql } from 'gatsby'
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const TermsOfUse = () => {
  const { language } = useI18next()
  const { t } = useTranslation('pages', { keyPrefix: 'terms.termsOfUse' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage>
            <p>
              <Trans i18nKey="intro.text.0" t={t}>
                Valid from: January 2020
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.1" t={t}>
                These Terms and Conditions (&quot;Terms&quot;, &quot;Terms and
                Conditions&quot;) govern your relationship with{' '}
                <Link language={language} to="/">
                  https://www.flagis.com
                </Link>{' '}
                website and Flagis mobile application (the &quot;Service&quot;)
                operated by Flagis s.r.o. (&quot;us&quot;, &quot;we&quot;, or
                &quot;our&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.2" t={t}>
                Please read these Terms and Conditions carefully before using
                our website and Flagis mobile application (the
                &quot;Service&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.3" t={t}>
                Your access to and use of the Service is conditioned on your
                acceptance of and compliance with these Terms. These Terms apply
                to all visitors, users and others who access or use the Service.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.4" t={t}>
                By accessing or using the Service you agree to be bound by these
                Terms. If you disagree with any part of the terms then you may
                not access the Service.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="subscriptions.title" t={t}>
                Subscriptions
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="subscriptions.text.0" t={t}>
                Some parts of the Service are billed on a subscription basis
                (&quot;Subscription(s)&quot;). You will be billed in advance on
                a recurring and periodic basis (&quot;Billing Cycle&quot;).
                Billing cycles are set either on a monthly or annual basis,
                depending on the type of subscription plan you select when
                purchasing a Subscription.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="subscriptions.text.1" t={t}>
                At the end of each Billing Cycle, your Subscription will
                automatically renew under the exact same conditions unless you
                cancel it or Flagis s.r.o. cancels it. You may cancel your
                Subscription renewal either through your online account
                management page or by contacting Flagis s.r.o. customer support
                team.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="subscriptions.text.2" t={t}>
                A valid payment method, including credit card or PayPal, is
                required to process the payment for your Subscription. You shall
                provide Flagis s.r.o. with accurate and complete billing
                information including full name, address, state, zip code,
                telephone number, and a valid payment method information. By
                submitting such payment information, you automatically authorize
                Flagis s.r.o. to charge all Subscription fees incurred through
                your account to any such payment instruments.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="subscriptions.text.3" t={t}>
                Should automatic billing fail to occur for any reason, Flagis
                s.r.o. will issue an electronic invoice indicating that you must
                proceed manually, within a certain deadline date, with the full
                payment corresponding to the billing period as indicated on the
                invoice.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="freeTrial.title" t={t}>
                Free Trial
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="freeTrial.text.0" t={t}>
                Flagis s.r.o. may, at its sole discretion, offer a Subscription
                with a free trial for a limited period of time (&quot;Free
                Trial&quot;).
              </Trans>
            </p>
            <p>
              <Trans i18nKey="freeTrial.text.1" t={t}>
                You may be required to enter your billing information in order
                to sign up for the Free Trial.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="freeTrial.text.2" t={t}>
                If you do enter your billing information when signing up for the
                Free Trial, you will not be charged by Flagis s.r.o. until the
                Free Trial has expired. On the last day of the Free Trial
                period, unless you cancelled your Subscription, you will be
                automatically charged the applicable Subscription fees for the
                type of Subscription you have selected.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="freeTrial.text.3" t={t}>
                At any time and without notice, Flagis s.r.o. reserves the right
                to (i) modify the terms and conditions of the Free Trial offer,
                or (ii) cancel such Free Trial offer.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="feeChanges.title" t={t}>
                Fee Changes
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="feeChanges.text.0" t={t}>
                Flagis s.r.o., in its sole discretion and at any time, may
                modify the Subscription fees for the Subscriptions. Any
                Subscription fee change will become effective at the end of the
                then-current Billing Cycle.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="feeChanges.text.1" t={t}>
                Flagis s.r.o. will provide you with a reasonable prior notice of
                any change in Subscription fees to give you an opportunity to
                terminate your Subscription before such change becomes
                effective.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="feeChanges.text.2" t={t}>
                Your continued use of the Service after the Subscription fee
                change comes into effect constitutes your agreement to pay the
                modified Subscription fee amount.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="refunds.title" t={t}>
                Refunds
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="refunds.text.0" t={t}>
                Except when required by law, paid Subscription fees are
                non-refundable.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="content.title" t={t}>
                Content
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="content.text.0" t={t}>
                Our Service allows you to post, link, store, share and otherwise
                make available certain information, text, graphics, videos, or
                other material (&quot;Content&quot;). You are responsible for
                the Content that you post to the Service, including its
                legality, reliability, and appropriateness.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="content.text.1" t={t}>
                By posting Content to the Service, you grant us the right and
                license to use, modify, perform, display, reproduce, and
                distribute such Content on and through the Service. You retain
                any and all of your rights to any Content you submit, post or
                display on or through the Service and you are responsible for
                protecting those rights.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="content.text.2" t={t}>
                You represent and warrant that: (i) the Content is yours (you
                own it) or you have the right to use it and grant us the rights
                and license as provided in these Terms, and (ii) the posting of
                your Content on or through the Service does not violate the
                privacy rights, publicity rights, copyrights, contract rights or
                any other rights of any person.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="accounts.title" t={t}>
                Accounts
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="accounts.text.0" t={t}>
                When you create an account with us, you must provide us
                information that is accurate, complete, and current at all
                times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of your account on our
                Service.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="accounts.text.1" t={t}>
                You are responsible for safeguarding the password that you use
                to access the Service and for any activities or actions under
                your password, whether your password is with our Service or a
                third-party service.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="accounts.text.2" t={t}>
                You agree not to disclose your password to any third party. You
                must notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="accounts.text.3" t={t}>
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trade mark that is subject to any rights of another person or
                entity other than you without appropriate authorization, or a
                name that is otherwise offensive, vulgar or obscene.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="intellectualProperty.title" t={t}>
                Intellectual Property
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="intellectualProperty.text.0" t={t}>
                The Service and its original content (excluding Content provided
                by users), features and functionality are and will remain the
                exclusive property of Flagis s.r.o. and its licensors. The
                Service is protected by copyright, trademark, and other laws of
                both the Czech Republic and foreign countries. Our trademarks
                and trade dress may not be used in connection with any product
                or service without the prior written consent of Flagis s.r.o..
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="links.title" t={t}>
                Links To Other Web Sites
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="links.text.0" t={t}>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by Flagis s.r.o..
              </Trans>
            </p>
            <p>
              <Trans i18nKey="links.text.1" t={t}>
                Flagis s.r.o. has no control over, and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party web sites or services. You further acknowledge and agree
                that Flagis s.r.o. shall not be responsible or liable, directly
                or indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such
                content, goods or services available on or through any such web
                sites or services.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="links.text.2" t={t}>
                We strongly advise you to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                you visit.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="termination.title" t={t}>
                Termination
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="termination.text.0" t={t}>
                We may terminate or suspend your account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if you breach the Terms.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="termination.text.1" t={t}>
                Upon termination, your right to use the Service will immediately
                cease. If you wish to terminate your account, you may simply
                discontinue using the Service.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="limitationOfLiability.title" t={t}>
                Limitation Of Liability
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="limitationOfLiability.text.0" t={t}>
                In no event shall Flagis s.r.o., nor its directors, employees,
                partners, agents, suppliers, or affiliates, be liable for any
                indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from (i)
                your access to or use of or inability to access or use the
                Service; (ii) any conduct or content of any third party on the
                Service; (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or
                content, whether based on warranty, contract, tort (including
                negligence) or any other legal theory, whether or not we have
                been informed of the possibility of such damage, and even if a
                remedy set forth herein is found to have failed of its essential
                purpose.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="disclaimer.title" t={t}>
                Disclaimer
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="disclaimer.text.0" t={t}>
                Your use of the Service is at your sole risk. The Service is
                provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
                basis. The Service is provided without warranties of any kind,
                whether express or implied, including, but not limited to,
                implied warranties of merchantability, fitness for a particular
                purpose, non-infringement or course of performance.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="disclaimer.text.1" t={t}>
                Flagis s.r.o. its subsidiaries, affiliates, and its licensors do
                not warrant that a) the Service will function uninterrupted,
                secure or available at any particular time or location; b) any
                errors or defects will be corrected; c) the Service is free of
                viruses or other harmful components; or d) the results of using
                the Service will meet your requirements.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="goveringLaw.title" t={t}>
                Governing Law
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="goveringLaw.text.0" t={t}>
                These Terms shall be governed and construed in accordance with
                the laws of Czech Republic, without regard to its conflict of
                law provisions.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="goveringLaw.text.1" t={t}>
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect. These Terms constitute the entire agreement between
                us regarding our Service, and supersede and replace any prior
                agreements we might have between us regarding the Service.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="changes.title" t={t}>
                Changes
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="changes.text.0" t={t}>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will try to provide at least 30 days notice prior to any new
                terms taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="changes.text.1" t={t}>
                By continuing to access or use our Service after those revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, please stop using the
                Service.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="contactUs.title" t={t}>
                Contact Us
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="contactUs.text.0" t={t}>
                If you have any questions about this Terms and Conditions,
                please contact us:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="contactUs.text.1" t={t}>
                  By email: <a href="mailto:info@flagis.com">info@flagis.com</a>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="contactUs.text.2" t={t}>
                  By visiting this page on our website:{' '}
                  <Link language={language} to="/contact-us">
                    https://www.flagis.com/contact-us
                  </Link>
                </Trans>
              </li>
            </ul>
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default TermsOfUse

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
